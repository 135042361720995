import React from "react";
import { useTranslation } from "react-i18next";

import { VIEW_WELCOME } from "../../../constants/views";
import { TRAINING_LOCALES } from "../../../constants/training_languages";
import css from "./Home.module.scss";

const Home = ({ setView, setTrainingLocale }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const locales = TRAINING_LOCALES[language];

  return (
    <section className={css.home}>
      <div className={css.home__inner}>
        <div className={css.home__head}>
          <h1 className={css.home__heading}>{t("first:title")}</h1>
        </div>

        <div className={css.home__buttons}>
          {locales.map((locale) => (
            <div key={locale} className={css.home__button}>
              <div
                className={css.button}
                onClick={() => {
                  setTrainingLocale(locale);
                  setView(VIEW_WELCOME);
                }}
              >
                {t(`first:locale_${locale}`)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Home;
