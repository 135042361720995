import { useState} from "react";
import { Layout } from "./components/common";
import { VIEWS, VIEW_LANGUAGE } from "./constants/views";

function App() {
  const [view, setView] = useState(VIEW_LANGUAGE);
  const [trainingLocale, setTrainingLocale] = useState(null);
  const View = VIEWS[view];

  return (
    <Layout view={view} setView={setView}>
      <View
        setView={setView}
        trainingLocale={trainingLocale}
        setTrainingLocale={setTrainingLocale}
      />
    </Layout>
  );
}

export default App;
