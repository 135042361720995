import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LOCALES_DE, LOCALES_IT } from "./constants/locales";

i18n.use(initReactI18next).init({
  resources: {
    de: LOCALES_DE,
    it: LOCALES_IT,
  },
  lng: "de",
  fallbackLng: ["de", "it"],
  interpolation: {
    escapeValue: false,
  },
  defaultNS: "common",
  react: {
    useSuspense: false,
  },
});
