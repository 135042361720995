const LOCALES_IT = {
  first: {
    "language": "DEUTSCH",
    "title": "QUALE LINGUA VUOI IMPARARE?",
    "locale_de": "Tedesco",
    "locale_en": "Inglese"
  },
  start: {
    "start": "INIZIO",
    "text": "Il nostro amico Giacomino questa mattina ha trovato nel Castel Trauttmansdorff una macchina del tempo!" +
      " Incuriosito, ha spinto qualche pulsante, ha girato alcune manovelle e all’improvviso Giacomino si è ritrovato in un viaggio nel tempo.",
    "text_2": "Sei pronto? Iniziamo.",
    "button": "Iniziamo"
  },
  popup: {
    "title": "Purtroppo non è la parola giusta - prova di nuovo! "
  },
  question: {
    "first": "Domanda",
    "second": "di",
    "button": "Avanti"
  },
  end: {
    "end": "FINE",
    "text": "COMPLIMENTI!",
    "text_2": "Il viaggio con Giacomino è finito e tu hai completato con successo tutte le tappe. ",
    "text_3": "Ci vediamo presto al Touriseum!",
    "button": "Avanti"
  },
  last: {
    "end": "FINE",
    "text": "Ora scrivi le parole che hai imparato in questo elenco per non dimenticarle.",
    "link": "Scarica l'elenco",
    "button": "Esci"
  }
}

const LOCALES_DE = {
  first: {
    "language": "Italiano",
    "title": "WELCHE SPRACHE MÖCHTEST DU ERLERNEN?",
    "locale_it": "Italienisch",
    "locale_en": "Englisch"
  },
  start: {
    "start": "start",
    "text": "Heute Morgen hat unser Freund Jakob im Touriseum Schloss Trauttmansdorff eine Zeitmaschine entdeckt. " +
      "Neugierig geworden, drückt er einige Knöpfe, dreht an den Kurbeln und plötzlich befindet sich Jakob auf einer Zeitreise.",
    "text_2": "Bist du bereit? Das Spiel kann beginnen!",
    "button": "Los geht’s"
  },
  popup: {
    "title": "Das ist leider nicht das richtige Wort - probiere es noch einmal! "
  },
  question: {
    "first": "Frage",
    "second": "von",
    "button": "Weiter"
  },
  end: {
    "end": "ENDE",
    "text": "GRATULATION!",
    "text_2": "Die Reise mit Jakob ist zu Ende und du hast alle Etappen erfolgreich bestanden. ",
    "text_3": "Wir sehen uns bald im Touriseum!",
    "button": "Weiter"
  },
  last: {
    "end": "ENDE",
    "text": "Schreibe die neuen Wörter in die Liste. So bleibt diese fantastische Reise unvergessen.",
    "link": "Liste herunterladen",
    "button": "Beenden"
  }
}

export {
  LOCALES_IT,
  LOCALES_DE
}