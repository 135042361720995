import React from "react";
import Header from "../Header";

import css from "./Layout.module.scss";

const Layout = ({ children, view, setView }) => {
  return (
    <div className={css.layout}>
      <div className={css.layout__header}>
        <Header view={view} setView={setView} />
      </div>
      <div className={css.layout__main}>
        <div className={css.layout__inner}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
