import React from "react";
import { useTranslation } from "react-i18next";

import { VIEW_QUESTIONS } from "../../../constants/views";
import css from "./Start.module.scss";
import car from "./images/0_Screen_TimeMaschine.jpeg";
import child from "./images/child.png";

export default function Start({ setView }) {
  const { t } = useTranslation();

  return (
    <section className={css.view}>
      <div className={css.view__content}>
        <div className={css.view__head}>
          <div className={css.view__heading}>{t("start:start")}</div>
        </div>
        <div className={css.view__row}>
          <div className={css.view__left}>
            <div className={css.view__text}>
              {t("start:text")} <br />
              <br /> {t("start:text_2")}
            </div>
          </div>
          <div className={css.view__right}>
            <div className={css.view__thumbs}>
              <div className={css.view__thumb}>
                <img src={car} alt="" />
              </div>
              <div className={css.view__thumb2}>
                <img src={child} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.view__footer}>
        <div onClick={() => setView(VIEW_QUESTIONS)} className={css.button}>
          {t("start:button")}
        </div>
      </div>
    </section>
  );
}
