import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import css from "./Questions.module.scss";
import { VIEW_FINISH } from "../../../constants/views";
import { Popup } from "../index";
import { TRAINING_DATA } from "../../../constants/training_languages";

const initialAnswer = {
  isCorrect: null,
  index: null,
};

export default function Questions({ setView, trainingLocale }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(0);
  const [answer, setAnswer] = useState(initialAnswer);
  const questions = TRAINING_DATA[language][trainingLocale];
  const answers = useMemo(
    () =>
      [...questions[activeIndex].answers].sort(() => {
        return 0.5 - Math.random();
      }),
    [activeIndex, questions]
  );

  const handleVote = (answer) => {
    setAnswer(answer);
  };

  const handleNext = () => {
    if (activeIndex === questions.length - 1) {
      setView(VIEW_FINISH);
      return;
    }

    setAnswer(initialAnswer);
    setActiveIndex(activeIndex + 1);
  };

  return (
    <section className={css.questions}>
      {answer.isCorrect === false && (
        <Popup handleCloseModal={() => setAnswer(initialAnswer)} />
      )}
      <div className={css.questions__content}>
        <div className={css.questions__head}>
          <div className={css.questions__heading}>
            {`${t("question:first")} ${activeIndex + 1} ${t(
              "question:second"
            )} ${questions?.length}`}
          </div>
        </div>
        <div className={css.questions__row}>
          <div className={css.questions__left}>
            <div className={css.questions__question}>
              <div className={css.question}>
                <div className={css.question__date}>
                  {questions[activeIndex].date}
                </div>
                <div
                  className={css.question__text}
                  dangerouslySetInnerHTML={{
                    __html: questions[activeIndex]?.question,
                  }}
                />
              </div>
            </div>
            <div className={css.questions__image}>
              <img src={`/image/${activeIndex + 1}.png`} alt="" />
            </div>
            <div className={css.questions__list}>
              {answers.map((item, index) => (
                <div
                  key={index + Date.now()}
                  onClick={() =>
                    handleVote({
                      isCorrect: item.correct,
                      index,
                    })
                  }
                  className={cn({
                    [css.questions__item]: true,
                    [css.questions__item_correct]:
                      item.correct &&
                      answer.isCorrect === true &&
                      answer.index === index,
                    [css.questions__item_incorrect]:
                      !item.correct &&
                      answer.isCorrect === false &&
                      answer.index === index,
                  })}
                >
                  <span>{index + 1}</span> {item.answer}
                </div>
              ))}
            </div>
          </div>
          <div className={css.questions__right}>
            <div className={css.questions__thumb}>
              <img src={`/image/${activeIndex + 1}.png`} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className={css.questions__footer}>
        <div
          onClick={handleNext}
          className={cn({
            [css.button]: true,
            [css.button_invisible]: answer.isCorrect !== true,
          })}
        >
          {t("question:button")}
        </div>
      </div>
    </section>
  );
}
