import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { VIEW_LANGUAGE } from "../../../constants/views";
import css from "./Last.module.scss";

const PDF_LINKS = {
  de_en: "/pdf/Pdf Download_German_English.pdf",
  de_it: "/pdf/Pdf Download_German_Italian.pdf",
  it_de: "/pdf/Pdf.Liste_Download_Italian_German.pdf",
  it_en: "/pdf/Pdf.LISTE_Download_Italian_English.pdf",
};

export default function Last({ setView, trainingLocale }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <section className={css.view}>
      <div className={css.view__content}>
        <div className={css.view__head}>
          <div className={css.view__heading}>{t("last:end")}</div>
        </div>
        <div className={css.view__row}>
          <div className={css.view__text}>
            <p>{t("last:text")}</p>
          </div>
          <a
            download
            href={`${PDF_LINKS[`${language}_${trainingLocale}`]}`}
            className={cn([css.button, css.button_white])}
          >
            {t("last:link")}
          </a>
        </div>
      </div>
      <div className={css.view__footer}>
        <div onClick={() => setView(VIEW_LANGUAGE)} className={css.button}>
          {t("last:button")}
        </div>
      </div>
    </section>
  );
}
