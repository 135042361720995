import React from "react";

export default function Reload() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 201.313 201.989">
      <g id="replay_black_24dp" transform="translate(119.385) rotate(56)">
        <path id="Контур_13" data-name="Контур 13" d="M146.51,0H0V144H146.51Z" fill="none"/>
        <path id="Контур_14" data-name="Контур 14" d="M52.837,25V1L83.36,31,52.837,61V37C32.631,37,16.209,53.142,16.209,73s16.421,36,36.628,36S89.464,92.863,89.464,73h12.209c0,26.521-21.854,48-48.837,48S4,99.523,4,73,25.854,25,52.837,25Z" transform="translate(20.418 5)" fill="#e60032"/>
      </g>
    </svg>

  )
}