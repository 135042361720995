import DE_IT from "../json/de_it.json";
import DE_EN from "../json/de_en.json";
import IT_DE from "../json/it_de.json";
import IT_EN from "../json/it_en.json";

export const TRAINING_LANGUAGE_DE_IT = "TRAINING_LANGUAGE_DE_IT";
export const TRAINING_LANGUAGE_DE_EN = "TRAINING_LANGUAGE_DE_EN";
export const TRAINING_LANGUAGE_IT_DE = "TRAINING_LANGUAGE_IT_DE";
export const TRAINING_LANGUAGE_IT_EN = "TRAINING_LANGUAGE_IT_EN";

export const TRAINING_LANGUAGES = {
  [TRAINING_LANGUAGE_DE_IT]: DE_IT,
  [TRAINING_LANGUAGE_DE_EN]: DE_EN,
  [TRAINING_LANGUAGE_IT_DE]: IT_DE,
  [TRAINING_LANGUAGE_IT_EN]: IT_EN,
};

export const LOCALE_EN = "en";
export const LOCALE_DE = "de";
export const LOCALE_IT = "it";

export const LOCALES = [LOCALE_DE, LOCALE_IT];
export const TRAINING_LOCALES = {
  [LOCALE_DE]: [LOCALE_IT, LOCALE_EN],
  [LOCALE_IT]: [LOCALE_DE, LOCALE_EN],
};

export const TRAINING_DATA = {
  [LOCALE_DE]: {
    [LOCALE_IT]: TRAINING_LANGUAGES[TRAINING_LANGUAGE_DE_IT],
    [LOCALE_EN]: TRAINING_LANGUAGES[TRAINING_LANGUAGE_DE_EN],
  },
  [LOCALE_IT]: {
    [LOCALE_DE]: TRAINING_LANGUAGES[TRAINING_LANGUAGE_IT_DE],
    [LOCALE_EN]: TRAINING_LANGUAGES[TRAINING_LANGUAGE_IT_EN],
  },
};
