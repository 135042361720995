import React from "react";
import { useTranslation } from "react-i18next";

import { VIEW_END } from "../../../constants/views";
import css from "./End.module.scss";

import smile from "./images/x_Screen_HappyFace.jpg";
import picture from "./images/x_Screen_Pictures.JPG";

export default function End({ setView }) {
  const { t } = useTranslation();

  return (
    <section className={css.view}>
      <div className={css.view__content}>
        <div className={css.view__head}>
          <div className={css.view__heading}>{t("end:end")}</div>
        </div>
        <div className={css.view__row}>
          <div className={css.view__left}>
            <div className={css.view__text}>
              <span>{t("end:text")}</span> <br />
              <br /> {t("end:text_2")} <br />
              <br /> {t("end:text_3")}
            </div>
          </div>
          <div className={css.view__right}>
            <div className={css.view__thumbs}>
              <div className={css.view__thumb}>
                <img src={picture} alt="" />
              </div>
              <div className={css.view__thumb2}>
                <img src={smile} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.view__footer}>
        <div onClick={() => setView(VIEW_END)} className={css.button}>
          {t("end:button")}
        </div>
      </div>
    </section>
  );
}
