import React from "react";
import { useTranslation } from "react-i18next";

import Reload from "../../icons/Reload";
import css from "./Popup.module.scss";

export default function Popup({ handleCloseModal }) {
  const { t } = useTranslation();

  return (
    <div className={css.popup}>
      <div className={css.popup__overlay} />
      <div className={css.popup__content}>
        <div className={css.popup__close} onClick={handleCloseModal}></div>
        <div className={css.popup__title}>{t("popup:title")}</div>
        <div className={css.popup__reload} onClick={handleCloseModal}>
          <Reload />
        </div>
      </div>
    </div>
  );
}
