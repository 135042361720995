import { Home, Start, Questions, End, Last } from "../components/view";

export const VIEW_LANGUAGE = "VIEW_LANGUAGE";
export const VIEW_WELCOME = "VIEW_WELCOME";
export const VIEW_QUESTIONS = "VIEW_QUESTIONS";
export const VIEW_FINISH = "VIEW_FINISH";
export const VIEW_END = "VIEW_END";

export const VIEWS = {
  VIEW_LANGUAGE: Home,
  VIEW_WELCOME: Start,
  VIEW_QUESTIONS: Questions,
  VIEW_FINISH: End,
  VIEW_END: Last,
};
