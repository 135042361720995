import React from "react";
import { useTranslation } from "react-i18next";
import { VIEW_LANGUAGE } from "../../../constants/views";
import css from "./Header.module.scss";

const Header = ({ view, setView }) => {
  const { t, i18n } = useTranslation();

  const handlerChangeLanguage = () => {
    i18n.changeLanguage(i18n.languages.filter((l) => l !== i18n.language)[0]);
  };

  return (
    <header className={css.header}>
      <div className={css.header__inner}>
        <div className={css.header__logo} onClick={() => setView(VIEW_LANGUAGE)}>
          <img src="logo@2x.png" alt="logo" />
        </div>
        {view === VIEW_LANGUAGE && (
          <div
            className={css.header__language}
            onClick={() => handlerChangeLanguage()}
          >
            {t("first:language")}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
